.dive-header {
	background-color: white !important;
	max-width: 100% !important;
	position: fixed !important;
	width: 100% !important;
	z-index: 99 !important;
}

.linkavitch {
	color: #039be5;
	&:hover {
		color: darkblue;
	}
}
